
import Vue from 'vue';

import IcFacebookRounded from '@/assets/svg/ic_facebook_rounded.svg?inline';
import { FacebookApiLoginResponse, FacebookApiUserData, FacebookToken } from '@/infrastructure/auth/types';
import { _insertScript } from '@/utilities/misc/insertScript';

const connectedStatus = 'connected';

export default Vue.extend({
  name: 'ButtonConnectFacebook',
  components: {
    IcFacebookRounded,
  },
  data() {
    return {
      isFetchingSDK: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadFacebookSDK();
  },
  methods: {
    async loadFacebookSDK(): Promise<void> {
      try {
        this.setSDKFetchingState(true);

        await _insertScript({
          id: 'facebook-jssdk',
          src: 'https://connect.facebook.net/fr_FR/sdk.js',
        });

        this.initFacebookSDK();
      } catch (err) {
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.setSDKFetchingState(false);
      }
    },
    initFacebookSDK(): void {
      window.fbAsyncInit = (): void => {
        window.FB.init({
          appId: this.$config.facebook.app.id,
          xfbml: false,
          version: 'v12.0',
        });
      };
    },
    logIn(): void {
      window.FB.getLoginStatus((response: FacebookApiLoginResponse) => this.logInFacebookIfNecessary(response), { scope: 'email' });
    },
    getFacebookUserDataAndLogInSelency(response: FacebookApiLoginResponse): void {
      const { userID, accessToken } = response?.authResponse || {};
      const searchParams = new URLSearchParams();

      if (!userID || !accessToken) {
        return;
      }

      searchParams.append('access_token', accessToken);

      window.FB.api(
        `/me?${searchParams.toString()}`,
        { locale: this.$i18n.locale, fields: 'email,first_name,last_name,locale' },
        async (facebookUserData: FacebookApiUserData): Promise<void> => {
          await this.logInSelency(facebookUserData, accessToken);
        },
      );
    },
    logInFacebookIfNecessary(response: FacebookApiLoginResponse): void {
      if (response.status === connectedStatus) {
        this.getFacebookUserDataAndLogInSelency(response);
      } else {
        this.logInFacebook();
      }
    },
    logInFacebook(): void {
      window.FB.login((response: FacebookApiLoginResponse): void => this.getFacebookUserDataAndLogInSelency(response), { scope: 'email' });
    },
    async logInSelency(facebookUserData: FacebookApiUserData, facebookAccessToken: FacebookToken) {
      const userData = { ...facebookUserData };

      if (!userData.locale) {
        userData.locale = this.$i18n.locale;
      }

      try {
        this.setLoadingState(true);

        const { isNewUser } = await this.$services.authService.loginWithFacebook(userData.email, facebookAccessToken);
        const eventName = isNewUser ? 'register' : 'login';

        this.$emit(eventName);
      } catch (err) {
        this.$emit('error', err);
        this.$errorMonitor.report(err, 'fatal');
      } finally {
        this.setLoadingState(false);
      }
    },
    setLoadingState(state: boolean): void {
      this.isLoading = state;
    },
    setSDKFetchingState(state: boolean): void {
      this.isFetchingSDK = state;
    },
  },
});
